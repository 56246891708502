<div class="vehicle fade-in-bottom" *ngIf="vehicleForm">
  <ng-container *ngIf="isSignUpView()">
    <h2 class="head-title dark">{{ 'Quel véhicule possédez-vous ?' | translate }}</h2>
    <p class="subtitle d1">
      {{
        'Grâce à ces informations nous pourrons vous aider à fixer des tarifs intéressants.'
          | translate
      }}
    </p>
  </ng-container>

  <form class="vehicle-form" [formGroup]="vehicleForm">
    <!-- LICENSE PLATE -->
    <div>
      <div
        class="input-container no-icon has-label variant-signup"
        [ngClass]="{ 'has-error': showError(vehicleForm?.get('licensePlate'), submitted) }"
      >
        <span *ngIf="vehicleForm?.get('licensePlate').value" class="input-label fade-in-bottom-2">{{
          'Immatriculation' | translate
        }}</span>
        <ion-input
          [ngClass]="{ uppercase: vehicleForm?.get('licensePlate').value }"
          [formControlName]="'licensePlate'"
          [placeholder]="'Immatriculation' | translate"
        ></ion-input>
      </div>
      <p class="form-error" *ngIf="showError(vehicleForm?.get('licensePlate'), submitted)">
        {{ "Plaque d'immatriculation incorrecte" | translate }}
      </p>
    </div>
    <!-- BRAND -->
    <div>
      <div
        class="input-container has-label variant-signup"
        (click)="presentBrandSelectionAlert()"
        [ngClass]="{
          'has-error': showError(vehicleForm?.get('brandId'), submitted)
        }"
      >
        <span *ngIf="vehicleForm?.get('brandId').value" class="input-label">{{
          'Marque' | translate
        }}</span>
        <span class="form-field-value">{{
          getSelectedBrand()?.MAR_LIBELLE || ('Marque' | translate)
        }}</span>
        <tabler-icon icon="chevron-down" size="md"></tabler-icon>
      </div>
      <p class="form-error" *ngIf="showError(vehicleForm?.get('brandId'), submitted)">
        {{ 'Veuillez sélectionner une marque' | translate }}
      </p>
    </div>
    <ng-container *ngIf="vehicleForm?.get('model').value as selectedModel; else selectModel">
      <div class="model-card selected fade-in-bottom-2" (click)="removeSelectedModel()">
        <tabler-icon icon="car" size="sm" color="d2"></tabler-icon>
        <div class="model-details">
          <span class="model-label">{{
            selectedModel.label || ('Modèle inconnu' | translate)
          }}</span>
          <span class="model-info"
            >{{ selectedModel.MOE_ANNEE ? selectedModel.MOE_ANNEE + ' • ' : '' }}
            {{ Jaf.translate(selectedModel.MOE_ENE_ID?.ENE_LIBELLE) }}</span
          >
        </div>
        <tabler-icon icon="pencil" size="sm" color="d1"></tabler-icon>
      </div>
    </ng-container>
    <ng-template #selectModel>
      <div *ngIf="vehicleForm?.get('brandId').value">
        <ion-button
          class="select-model-btn white"
          (click)="toggleModelSelection(true)"
          [ngClass]="{
            'has-error': showError(vehicleForm?.get('model'), submitted)
          }"
          >{{ 'Sélectionner mon modèle' | translate }}</ion-button
        >
        <p class="form-error" *ngIf="showError(vehicleForm?.get('model'), submitted)">
          {{ 'Veuillez sélectionner un modèle' | translate }}
        </p>
      </div>
    </ng-template>
    <div *ngIf="vehicleForm?.get('model').value">
      <way-partner-date-picker
        [id]="'firstRegistrationDate'"
        [control]="vehicleForm?.get('firstRegistrationDate')"
        [maxDate]="maxDate"
        [minDate]="minDate"
        [placeholder]="'Date de mise en circulation' | translate"
        [showLabel]="true"
        [showError]="showError(vehicleForm?.get('firstRegistrationDate'), submitted)"
        [format]="'dd/MM/yyyy'"
      ></way-partner-date-picker>
      <p class="form-error" *ngIf="showError(vehicleForm?.get('firstRegistrationDate'), submitted)">
        {{ 'Date de mise en circulation obligatoire' | translate }}
      </p>
    </div>
  </form>
</div>

<!-- MODEL SELECTION MODAL -->
<ion-modal
  id="model-selection-modal"
  [initialBreakpoint]="1"
  [breakpoints]="[0, 1]"
  mode="ios"
  [isOpen]="showModelSelectionModal"
  (didDismiss)="toggleModelSelection(false)"
  class="model-selection-modal"
>
  <ng-template>
    <div class="model-modal-content" *ngIf="!showCustomVehicleForm; else customVehicleFormModal">
      <h3 class="head-title dark">
        {{ 'Modèles de' | translate }} {{ getSelectedBrand()?.MAR_LIBELLE }}
      </h3>
      <div class="input-container">
        <ion-input
          labelPlacement="stacked"
          label="model-search"
          [placeholder]="'Chercher un modèle..' | translate"
          (ionChange)="onFilterModels($event)"
        >
          <tabler-icon icon="search" size="sm" color="d2"></tabler-icon>
        </ion-input>
      </div>
      <section class="model-list-section">
        <div
          class="model-list"
          *ngIf="(models | VehicleModelFilter : keyword)?.length; else noModelFound"
        >
          <div
            *ngFor="let model of models | VehicleModelFilter : keyword"
            class="model-card"
            (click)="onSelectModel(model)"
          >
            <tabler-icon icon="car" size="sm" color="d2"></tabler-icon>
            <div class="model-details">
              <span class="model-label">{{ model.label }}</span>
              <span class="model-info"
                >{{ model.MOE_ANNEE ? model.MOE_ANNEE + ' •' : '' }}
                {{ Jaf.translate(model.MOE_ENE_ID.ENE_LIBELLE) }}</span
              >
            </div>
          </div>
        </div>
        <ng-template #noModelFound>
          <p class="no-model-found">
            {{
              keyword
                ? ('Aucun modèle ne correspond à votre recherche' | translate)
                : ('Aucun modèle trouvé' | translate)
            }}
          </p>
        </ng-template>
        <ion-button class="create-model-btn white" (click)="toggleCustomVehicleForm(true)">{{
          "Je n'ai pas trouvé mon modèle" | translate
        }}</ion-button>
      </section>
    </div>
    <ng-template #customVehicleFormModal>
      <div class="model-modal-content custom-vehicle-modal">
        <h3 class="head-title dark">
          {{ 'Modèle non répertorié' | translate }}
        </h3>
        <p class="subtitle d1">
          {{
            'WAY-Partner est une application encore en évolution.
            Merci de contribuer à son amélioration en ajoutant les
            informations de votre véhicule.' | translate
          }}
        </p>
        <form class="custom-vehicle-form" [formGroup]="customVehicleForm" *ngIf="customVehicleForm">
          <!-- MODEL INPUT -->
          <div>
            <div
              class="input-container no-icon has-label variant-signup"
              [ngClass]="{
                'has-error': showError(customVehicleForm?.get('model'), submittedCustomVehicle)
              }"
            >
              <span
                *ngIf="customVehicleForm?.get('model').value"
                class="input-label fade-in-bottom-2"
                >{{ 'Modèle' | translate }}</span
              >
              <ion-input
                [formControlName]="'model'"
                [placeholder]="'Modèle' | translate"
              ></ion-input>
            </div>
            <p
              class="form-error"
              *ngIf="showError(customVehicleForm?.get('model'), submittedCustomVehicle)"
            >
              {{ 'Modèle obligatoire' | translate }}
            </p>
          </div>
          <!-- VEHICLE DATE -->
          <div>
            <way-partner-date-picker
              [id]="'vehicleDate'"
              [control]="customVehicleForm?.get('vehicleDate')"
              [maxDate]="maxDate"
              [minDate]="minDate"
              [placeholder]="'Année de sortie' | translate"
              [showLabel]="true"
              [presentation]="'year'"
              [format]="'yyyy'"
              [showError]="showError(customVehicleForm?.get('vehicleDate'), submittedCustomVehicle)"
            ></way-partner-date-picker>
            <p
              class="form-error"
              *ngIf="showError(customVehicleForm?.get('vehicleDate'), submittedCustomVehicle)"
            >
              {{ 'Année de sortie obligatoire' | translate }}
            </p>
          </div>
          <!-- ENERGY -->
          <div>
            <div
              class="input-container has-label variant-signup"
              (click)="presentEnergySelectionAlert()"
              [ngClass]="{
                'has-error': showError(customVehicleForm?.get('energy'), submittedCustomVehicle)
              }"
            >
              <span *ngIf="customVehicleForm?.get('energy').value" class="input-label">{{
                'Énergie' | translate
              }}</span>
              <span class="form-field-value">{{
                customVehicleForm?.get('energy').value
                  ? Jaf.translate(getSelectedEnergy().ENE_LIBELLE)
                  : ('Énergie' | translate)
              }}</span>
              <tabler-icon icon="chevron-down" size="md"></tabler-icon>
            </div>
            <p
              class="form-error"
              *ngIf="showError(customVehicleForm?.get('energy'), submittedCustomVehicle)"
            >
              {{ 'Énergie obligatoire' | translate }}
            </p>
          </div>

          <!-- VEHICLE TYPE -->
          <div>
            <div
              class="input-container has-label variant-signup"
              (click)="presentVehicleTypeSelectionAlert()"
              [ngClass]="{
                'has-error': showError(customVehicleForm?.get('type'), submittedCustomVehicle)
              }"
            >
              <span *ngIf="customVehicleForm?.get('type').value" class="input-label">{{
                'Type de véhicule' | translate
              }}</span>
              <span class="form-field-value">{{
                customVehicleForm?.get('type').value
                  ? Jaf.translate(getSelectedVehicleType()?.TVH_LIBELLE)
                  : ('Type de véhicule' | translate)
              }}</span>
              <tabler-icon icon="chevron-down" size="md"></tabler-icon>
            </div>
            <p
              class="form-error"
              *ngIf="showError(customVehicleForm?.get('type'), submittedCustomVehicle)"
            >
              {{ 'Type de véhicule obligatoire' | translate }}
            </p>
          </div>

          <div class="passenger-luggages-inputs">
            <!-- MAX PASSENGERS INPUT -->
            <div class="flex-1">
              <div
                class="input-container has-label variant-signup"
                (click)="presentMaxPassengersAmountSelectionAlert()"
                [ngClass]="{
                  'has-error': showError(
                    customVehicleForm?.get('maxPassenger'),
                    submittedCustomVehicle
                  )
                }"
              >
                <span
                  *ngIf="customVehicleForm?.get('maxPassenger').value"
                  class="input-label fade-in-bottom-2"
                  >{{ 'Passagers max' | translate }}</span
                >
                <span class="form-field-value">{{
                  customVehicleForm?.get('maxPassenger').value
                    ? customVehicleForm?.get('maxPassenger').value
                    : ('Passagers max' | translate)
                }}</span>
                <tabler-icon icon="chevron-down" size="md"></tabler-icon>
              </div>
              <p
                class="form-error"
                *ngIf="showError(customVehicleForm?.get('maxPassenger'), submittedCustomVehicle)"
              >
                {{ 'Passagers max obligatoire' | translate }}
              </p>
            </div>
            <!-- MAX LUGGAGES INPUT -->
            <div class="flex-1">
              <div
                class="input-container has-label variant-signup"
                (click)="presentMaxLuggagesAmountSelectionAlert()"
                [ngClass]="{
                  'has-error': showError(
                    customVehicleForm?.get('maxLuggages'),
                    submittedCustomVehicle
                  )
                }"
              >
                <span
                  *ngIf="customVehicleForm?.get('maxLuggages').value"
                  class="input-label fade-in-bottom-2"
                  >{{ 'Bagages max' | translate }}</span
                >
                <span class="form-field-value">{{
                  customVehicleForm?.get('maxLuggages').value
                    ? customVehicleForm?.get('maxLuggages').value
                    : ('Bagages max' | translate)
                }}</span>
                <tabler-icon icon="chevron-down" size="md"></tabler-icon>
              </div>
              <p
                class="form-error"
                *ngIf="showError(customVehicleForm?.get('maxLuggages'), submittedCustomVehicle)"
              >
                {{ 'Bagages max obligatoire' | translate }}
              </p>
            </div>
          </div>
          <!-- PRESTIGE -->
          <div>
            <div
              class="input-container has-label variant-signup"
              (click)="presentPrestigeSelectionAlert()"
              [ngClass]="{
                'has-error': showError(customVehicleForm?.get('prestige'), submittedCustomVehicle)
              }"
            >
              <span *ngIf="customVehicleForm?.get('prestige').value" class="input-label">{{
                'Niveau de prestige' | translate
              }}</span>
              <span class="form-field-value">{{
                customVehicleForm?.get('prestige').value
                  ? Jaf.translate(getSelectedPrestige()?.NPR_LIBELLE)
                  : ('Niveau de prestige' | translate)
              }}</span>
              <tabler-icon icon="chevron-down" size="md"></tabler-icon>
            </div>
            <p
              class="form-error"
              *ngIf="showError(customVehicleForm?.get('prestige'), submittedCustomVehicle)"
            >
              {{ 'Niveau de prestige obligatoire' | translate }}
            </p>
          </div>
        </form>
        <!-- VALIDATE BTN -->
        <div class="btn-group">
          <ion-button
            [ngClass]="{ disabled: customVehicleForm.invalid }"
            class="next-btn"
            fill="filled"
            expand="block"
            (click)="submitCustomVehicle()"
          >
            {{ 'Suivant' | translate }}
            <tabler-icon icon="arrow-narrow-right" size="md"></tabler-icon>
          </ion-button>
        </div>
      </div>
    </ng-template>
  </ng-template>
</ion-modal>
