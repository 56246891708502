import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  templateUrl: './list-modal.component.html',
  styleUrls  : ['./list-modal.component.scss'],
})
export class ListModalComponent {
  @Input() title: string;

  @Input() items: any[] = [];

  @Input() icon: string;

  constructor(public modalController: ModalController) {}
}
